<template>
  <Checkout
    ref="checkout"
    breakdown="Cost"
    :customer="customerStore.customer"
    :warnings_checked="warningSettings.showIssuePresentWarning && hasIssues ? warnings_checked && !isLoading : true"
    @cancel-order="cancelOrder"
    @redirect-process-place-order="redirectProcessPlaceOrder"
    @validate-save-order="validateSaveOrder"
  >
    <template #order-review>
      <div
        v-if="$refs.checkout && !$refs.checkout.editing_delivery_address"
        id="show_terms"
        key="2"
        class="bg-white shadow-lg rounded-lg p-6 xs:p-10 mt-6 xs:mt-10 mb-10 xs:mb-0"
        :class="{ 'border border-red-500': isReview }"
        data-index="2"
      >
        <div class="border-b border-gray-200 pb-3 mb-3">
          <span class="text-lg">
            <i class="fal fa-check text-green-600 mr-3 hidden 2xs:inline" /> 
            3. Order Review
          </span>
          <div v-if="isReview" class="text-red-500">You must complete this section to continue.</div>
        </div>
        <div class="flex flex-col gap-5 xs:gap-10 p-5 max-w-4xl">
          <div v-if="warningSettings.showIssuePresentWarning && hasIssues" class="flex gap-5">
            <label>
              <input v-model="warnings_checked" type="checkbox" @change="updateIssuePresentConfirmed">
            </label>
            <div class="leading-tight">
              {{ warningSettings.issuePresentWarning }}
            </div>
          </div>
          <div class="flex gap-5">
            <label>
              <input v-model="$refs.checkout.terms_checked" type="checkbox">
            </label>
            <div class="leading-tight">
              Before ticking this box, please
              <a
                class="text-green-600 hover:text-green-800 cursor-pointer"
                :href="$refs.checkout.privacyPolicyDocumentUrl"
                target="_blank"
              >
                Click here
              </a>
              for more information about how we will use your personal information. By ticking this
              box you confirm that you understand and agree to our
              <a
                class="text-green-600 hover:text-green-800 cursor-pointer"
                :href="$refs.checkout.privacyPolicyDocumentUrl"
                target="_blank"
              >
                privacy policy
              </a>
              and
              <a
                class="text-green-600 hover:text-green-800 cursor-pointer"
                :href="$refs.checkout.termsDocumentUrl"
                target="_blank"
              >
                terms & conditions.
              </a>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="basketStore.isOrder && !isManualPricing" #voucher="{ type, itemKey, override }">
      <VoucherCode 
        :class="{ 'text-center': type === 'order', 'max-w-sm': type === 'item' }"
        :type="type"
        :item-key="itemKey"
        :is-applied="override && basketStore.job?.discountApplied"
      />
    </template>
  </Checkout>
</template>

<script>

import { mapStores } from 'pinia'
import { useBasketStore } from '@/pinia/basket';
import { useCustomerStore } from '@/pinia/customer';
import { useStyleStore } from '@/pinia/style';
import Checkout from '@/components/shared/checkout/Checkout.vue';
import VoucherCode from '@/components/shared/basket/VoucherCode.vue';

export default {
  components: {
    Checkout,
    VoucherCode
  },
  data() {
    return {
      warningSettings: {},
      warnings_checked: false,
      isLoading: false
    }
  },
  computed: {
    ...mapStores(useBasketStore, useCustomerStore, useStyleStore),
    isManualPricing() {
      return (this.basketStore.job?.overrideCalculatedPrice || this.basketStore.basket?.some(x => x.override)) && !this.basketStore.job?.discountApplied
    },
    isReview() {
      const checkout = this.$refs.checkout
      if (!checkout) {
        return false
      }
      return checkout.show_errors && (!checkout.terms_checked || (this.warningSettings.showIssuePresentWarning && this.hasIssues && !this.warnings_checked))
    },
    hasIssues() {
      const basket = this.basketStore.basket
      let issues = []
      for (let i = 0; i < basket.length; i++) {
        issues = issues.concat(basket[i].issues)
      }
      return issues.length
    }
  },
  async mounted() {
    await this.getCompanyIssuePresent()
  },
  methods: {
    cancelOrder() {
      this.basketStore.clearBasket();
      this.$router.go(-1);
    },
    async validateSaveOrder(saveOrder) {
      await this.styleStore.loadFabricatorStyle()
      if (this.styleStore.fabricator.portalOnStop) {
        window.alertBox.fire({
          title: 'System under temporary maintenance',
          text: 'Sorry for the inconvenience. Please check back soon.'
        });
      } else {
        saveOrder()
      }
    },
    redirectProcessPlaceOrder({ isSuccess }) {
      if (isSuccess) {
        this.routerPush('/orders');
      } else {
        this.routerPush('/orders');
      }
      this.basketStore.clearBasket()
    },
    async getCompanyIssuePresent() {
      const { showIssuePresentWarning, issuePresentWarning } = await window.touch.GetCompanyIssuePresent()
      this.warningSettings = { showIssuePresentWarning, issuePresentWarning }
    },
    async updateIssuePresentConfirmed() {
      this.isLoading = true
      await window.touch.UpdateIssuePresentConfirmed({ ContractId: this.basketStore.contractData.id, IssuePresentAccepted: this.warnings_checked })
      this.isLoading = false
    }
  },
};
</script>
